<template>
    <!-- 协同办公 > 校园OA > 家长出入校 -->
    <parent-access-scroll-wrapper></parent-access-scroll-wrapper>
</template>

<script>
import ParentAccessScrollWrapper from "@/components/scrollWrapper/ParentAccess";

export default {
    name: "ParentAccess",
    components: {
        ParentAccessScrollWrapper,
    },
};
</script>

<style scoped>

</style>
